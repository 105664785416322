exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allinfo-js": () => import("./../../../src/pages/allinfo.js" /* webpackChunkName: "component---src-pages-allinfo-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-form-avasta-js": () => import("./../../../src/pages/form-avasta.js" /* webpackChunkName: "component---src-pages-form-avasta-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-org-js": () => import("./../../../src/pages/index_org.js" /* webpackChunkName: "component---src-pages-index-org-js" */),
  "component---src-pages-indexhuman-js": () => import("./../../../src/pages/indexhuman.js" /* webpackChunkName: "component---src-pages-indexhuman-js" */),
  "component---src-pages-indexstd-js": () => import("./../../../src/pages/indexstd.js" /* webpackChunkName: "component---src-pages-indexstd-js" */),
  "component---src-pages-kiyaku-js": () => import("./../../../src/pages/kiyaku.js" /* webpackChunkName: "component---src-pages-kiyaku-js" */),
  "component---src-pages-privacy-avasta-js": () => import("./../../../src/pages/privacy-avasta.js" /* webpackChunkName: "component---src-pages-privacy-avasta-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-toresys-3-d-js": () => import("./../../../src/pages/privacy-toresys3d.js" /* webpackChunkName: "component---src-pages-privacy-toresys-3-d-js" */),
  "component---src-pages-require-js": () => import("./../../../src/pages/require.js" /* webpackChunkName: "component---src-pages-require-js" */),
  "component---src-posts-blog-post-js": () => import("./../../../src/posts/blog-post.js" /* webpackChunkName: "component---src-posts-blog-post-js" */)
}

